import React from "react";
import { Breadcrumb } from 'gatsby-plugin-breadcrumb';
import { graphql, useStaticQuery } from "gatsby";

import { breadcrumbs } from "./breadcrumbs.module.scss";
import { ICrumb } from "../../models/crumb.model";
import { ISitePage } from "../../models/site-page.model";

interface IBreadcrumbsProps {
    className?: '',
    crumbs: ICrumb[],
}

interface IBreadcrumbsQueryResult {
    sitePage: ISitePage;
}

const Breadcrumbs: React.FC<IBreadcrumbsProps> = ({
    className = '',
    crumbs
}) => {
    const { sitePage }: IBreadcrumbsQueryResult = useStaticQuery(query);
    const homeCrumb = sitePage.context.breadcrumb.crumbs[0];
    return (
        <div className={`${className} ${breadcrumbs}`}>
            <Breadcrumb
                crumbs={[homeCrumb, ...crumbs]}
                crumbSeparator={'»'}
            />
        </div>
    );
};

const query = graphql`
    query {
        sitePage (context: {slug: {eq: "/"}}) {
            context {
                breadcrumb {
                    crumbs {
                        crumbLabel
                        pathname
                    }
                }
            }
        }
    }  
`;

export default Breadcrumbs;