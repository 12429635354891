import React from 'react';
import rehypeRaw from "rehype-raw";
import ReactMarkdown from "react-markdown";

import {
    sectionContent,
    intro,
    cmsContent,
    tagContent,
    heading,
    tagsContent,
    tag as tagClass,
} from './post-content.module.scss'
import { IPost } from "../../models/post.model";
import { useTranslation } from "../../hooks/use-translation";
import { config } from '../../config';

import Section from "../molecules/section";
import Tag from '../atoms/tag';

const { translationKeys } = config;

interface IPostContentProps {
    post: IPost,
}

const PostContent: React.FC<IPostContentProps> = ({post}) => {
    const { lead, content } = useTranslation(post, translationKeys.post);

    const tags = post.tags;

    return (
        <>
            <Section
                column={'narrow-right'}
                columnTablet={'regular'}
                columnPhone={'regular'}
                className={sectionContent}
            >
                <h2 className={intro}>
                    {lead}
                </h2>
                <ReactMarkdown
                    className={cmsContent}
                    // @ts-ignore
                    rehypePlugins={[rehypeRaw]}
                >
                    {content}
                </ReactMarkdown>
            </Section>
            {tags.length > 0 && (
                <Section
                    column={'regular'}
                    columnTablet={'full'}
                    columnPhone={'full'}
                    className={tagContent}
                >
                    <p className={heading}>Tagi: </p>
                    <ul className={tagsContent}>
                        {tags.map((tag) => (
                            <Tag
                                key={tag.tagId}
                                tag={tag}
                                className={tagClass}
                                before="#"
                            />
                        ))}
                    </ul>
                </Section>
            )}
        </>
    );
};

export default PostContent;
