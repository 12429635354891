import { Person } from "schema-dts";

import { IAuthorStructuredData } from "../../models/structured-data.model";
import { ISite } from "../../models/site.model";
import createImage from "./create-image";

export default function createAuthor(
    author: IAuthorStructuredData,
    siteMetadata: ISite['siteMetadata']
): Person {
    const { firstName, lastName, slug, image } = author;
    return {
        '@type': 'Person',
        name: `${firstName} ${lastName}`,
        url: `${siteMetadata.url}${slug}`,
        image: createImage({
            siteUrl: siteMetadata.url,
            imageUrl: image,
            caption: `${firstName} ${lastName}`
        })
    }
}