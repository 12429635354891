import React from "react";
import {
    FacebookShareButton,
    FacebookIcon,
    TwitterShareButton,
    TwitterIcon,
    LinkedinShareButton,
    LinkedinIcon,
} from "react-share";
import { useLocation } from "@reach/router";

import { shareButtons, button, shareText } from "./share-buttons.module.scss";

export interface IShareButtonProps {
    href?: string;
    className?: string;
}

const customIconProps = {
    size: 40,
    bgStyle: {
        fill: "none",
    },
    iconFillColor: "black",
};

export default function ShareButtons({ href, className }: IShareButtonProps) {
    const { href: defaultHref } = useLocation();
    const shareUrl = href ? href : defaultHref;

    return (
        <div className={`${shareButtons} ${className}`}>
            <p className={shareText}>Udostępnij: </p>
            <FacebookShareButton url={shareUrl} className={button}>
                <FacebookIcon {...customIconProps} />
            </FacebookShareButton>
            <TwitterShareButton url={shareUrl} className={button}>
                <TwitterIcon {...customIconProps} />
            </TwitterShareButton>
            <LinkedinShareButton url={shareUrl} className={button}>
                <LinkedinIcon {...customIconProps} />
            </LinkedinShareButton>
        </div>
    );
}
