import React, { useRef } from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import {
    container,
    leftSection,
    img,
    sectionImage,
    description,
    mainTitle,
    rightSection,
    elements,
    heading,
    singleImage,
    promotedPostWrapper,
    breadcrumbs,
    socialButtons,
} from "./post.module.scss";
import { IPost, IPostBrief } from "../../models/post.model";
import { IAuthor } from "../../models/author.model";
import QueryEdgeNode from "../../models/query-edge-node.model";
import { useTranslation } from "../../hooks/use-translation";
import useFontSizeFit from "../../hooks/use-font-size-fit";
import useMedia from "../../hooks/use-media";
import getNodes from "../../utils/get-nodes";
import getPostsWithAuthor from "../../utils/get-posts-with-author";
import { config } from "../../config";
import { IPageBase } from "../../models/page-base.model";
import { ISite } from "../../models/site.model";
import usePostStructuredData from "../../hooks/blog/use-post-structured-data";
import createBlogPost from "../../utils/structured-data/create-blog-post";
import { createGraph } from "../../utils/structured-data";

import PostContent from "../molecules/post-content";
import PostMeta from "../molecules/post-meta";
import MainLayout from "../../layouts/main-layout";
import SectionWrapper from "../molecules/section-wrapper";
import Section from "../molecules/section";
import PromotedPost from "../organisms/blog/promoted-post";
import PostsSection from "../organisms/sections/posts-section";
import AuthorSection from "../organisms/sections/blog/author-section";
import Link from "../atoms/link";
import Breadcrumbs from "../molecules/breadcrumbs";
import ShareButtons from "../molecules/share-buttons";

const { relations, translationKeys } = config;

interface IPostProps extends IPageBase {
    readonly data: {
        post: IPost;
        author: IAuthor;
        authors: QueryEdgeNode<IAuthor>;
        promotedPosts: QueryEdgeNode<IPostBrief>;
        site: ISite;
        allRelatedPosts: QueryEdgeNode<IPostBrief>;
    };
}

const Post: React.FC<IPostProps> = ({ data }) => {
    const { post, author, authors, promotedPosts, allRelatedPosts, site } = data;
    const { title, slug, lead } = useTranslation(post, translationKeys.post);

    const titleRef = useRef<HTMLHeadingElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const fontSize = useFontSizeFit(titleRef, containerRef);

    const [
        mainImage,
        mainImageAlt = "Portrait de Coco Chanel, Man Ray, 1935, Gelatin Silver",
    ] = useMedia({
        media: post.media,
        relation: relations.mainImage,
    });

    const [
        mainImageMobile,
        mainImageMobileAlt = "Portrait de Coco Chanel, Man Ray, 1935, Gelatin Silver",
    ] = useMedia({
        media: post.media,
        relation: relations.mainImageMobile,
    });

    const { url: coverImageSrc } = useMedia({
        media: post.media,
        relation: relations.coverImage,
    });

    const category = !!post.categories.length ? post.categories[0].name : "";

    const postCrumbs = [
        {
            crumbLabel: "Blog",
            pathname: "/blog/",
        },
        {
            crumbLabel: title,
            pathname: `/${slug}/`,
        },
    ];

    const promotedPostsWithAuthor = getPostsWithAuthor(getNodes(promotedPosts), getNodes(authors));

    const promotedPost =
        promotedPostsWithAuthor.length > 0 ? promotedPostsWithAuthor[0] : undefined;

    const relatedPosts = getNodes(allRelatedPosts);

    const postStructuredData = usePostStructuredData(post, author);
    const structuredData = createGraph(createBlogPost(postStructuredData, site.siteMetadata));

    return (
        <MainLayout
            overflow={false}
            SEOProps={{
                title,
                structuredData,
                image: coverImageSrc,
                description: lead,
            }}
        >
            <SectionWrapper className={container}>
                <Section
                    column={"full-left"}
                    columnTablet={"full"}
                    columnPhone={"full"}
                    className={leftSection}
                >
                    <div className={sectionImage}>
                        {mainImage && (
                            <>
                                <GatsbyImage
                                    className={img}
                                    alt={mainImageAlt}
                                    image={mainImage}
                                    objectPosition={"unset"}
                                />
                                {mainImageAlt && (
                                    <span className={description}>{mainImageAlt}</span>
                                )}
                            </>
                        )}
                    </div>
                </Section>

                <Section
                    column={"full-right"}
                    columnTablet={"full"}
                    columnPhone={"full"}
                    className={rightSection}
                >
                    <div className={elements} ref={containerRef}>
                        <div className={heading}>
                            <PostMeta unixDate={post.publishedAt} />
                            {!!category.length && (
                                <>
                                    <span> | </span>
                                    <p>{category}</p>
                                </>
                            )}
                        </div>
                        <div>
                            <h1
                                {...(fontSize
                                    ? {
                                          style: { fontSize: `${fontSize}px` },
                                      }
                                    : {})}
                                ref={titleRef}
                                className={mainTitle}
                            >
                                {title}
                            </h1>
                        </div>
                        <Breadcrumbs crumbs={postCrumbs} className={breadcrumbs} />
                    </div>
                    <div className={singleImage}>
                        {mainImageMobile && (
                            <GatsbyImage
                                className={img}
                                alt={mainImageMobileAlt}
                                image={mainImageMobile}
                            />
                        )}
                    </div>
                    <ShareButtons className={socialButtons} />
                </Section>
            </SectionWrapper>
            <div className={promotedPostWrapper}>
                {promotedPost && <PromotedPost post={promotedPost} />}
            </div>
            <PostContent post={post} />
            <AuthorSection
                author={author}
                avatarSize="medium"
                //TODO: change showSignature to true when the secondary image will be added in adm panel
                showSignature={false}
                renderName={({ children, className, slug }) => (
                    <Link to={slug} className={className}>
                        {children}
                    </Link>
                )}
            />
            {relatedPosts.length > 0 && (
                <PostsSection
                    posts={relatedPosts}
                    authors={getNodes(authors)}
                    titleKey="posts-section.related"
                    subtitleKey="common.more"
                />
            )}
        </MainLayout>
    );
};

export const query = graphql`
    query($language: String!, $articleId: Int!, $profileId: Int!, $relatedPostsIds: [Int!]) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }

        post(articleId: { eq: $articleId }) {
            ...postFields
        }

        author(profileId: { eq: $profileId }) {
            ...authorFields
        }

        authors: allAuthor {
            edges {
                node {
                    ...authorFields
                }
            }
        }

        allRelatedPosts: allPost(
            filter: { articleId: { in: $relatedPostsIds } }
            sort: { fields: publishedAt, order: DESC }
            limit: 4
        ) {
            edges {
                node {
                    ...postBriefFields
                }
            }
        }

        promotedPosts: allPost(
            filter: { isPromoted: { eq: true }, articleId: { ne: $articleId } }
            limit: 1
        ) {
            edges {
                node {
                    ...postBriefFields
                }
            }
        }

        site {
            ...siteFields
        }
    }
`;

export default Post;
