import { BlogPosting } from "schema-dts";

import { IPostStructuredData } from "../../models/structured-data.model";
import createImage from "./create-image";
import { ISite } from "../../models/site.model";
import createAuthor from "./create-author";
import createOrganisation from "./create-organisation";

export default function createBlogPost(
    post: IPostStructuredData,
    siteMetadata: ISite['siteMetadata']
): BlogPosting {
    const { name, description, image, slug, dateModified, datePublished, author } = post;
    return {
        '@type': 'BlogPosting',
        name,
        description,
        image: createImage({
            siteUrl: siteMetadata.url,
            imageUrl: image ? image : ''
        }),
        //@ts-ignore
        datePublished,
        //@ts-ignore
        dateModified,
        headline: name,
        mainEntityOfPage: {
            '@type': 'WebSite',
            '@id': `${siteMetadata.url}/${slug}`,
            name
        },
        author: createAuthor(author, siteMetadata),
        publisher: createOrganisation(siteMetadata)
    }
}