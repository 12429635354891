// extracted by mini-css-extract-plugin
export var avatar = "author-section-module--avatar---abmb";
export var bioText = "author-section-module--bio-text--ZRKJa";
export var bottomParagraph = "author-section-module--bottom-paragraph--H+i79";
export var content = "author-section-module--content--zraF3";
export var info = "author-section-module--info--20iEt";
export var link = "author-section-module--link--UMy+i";
export var medium = "author-section-module--medium--F8zXr";
export var name = "author-section-module--name--AZ5TV";
export var signature = "author-section-module--signature--17n0d";
export var small = "author-section-module--small--YEhix";
export var titleText = "author-section-module--title-text--DI3Nc";