import React from "react";
import {useI18next} from "../../../plugins/gatsby-plugin-ap-i18next/src/useI18next";
import Moment from "react-moment";

import {dateBox} from './post-meta.module.scss';

interface IPostMetaProps {
    unixDate?: number,
}

const PostMeta: React.FC<IPostMetaProps> = ({unixDate}) => {
    const {language} = useI18next();

    return (
        <div className={dateBox}>
            {unixDate && (
                <Moment
                    locale={language}
                    unix={true}
                    format="DD-MM-YYYY"
                >
                    {unixDate}
                </Moment>
            )}
        </div>
    );
};

export default PostMeta;