import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

import {
    promotedPost,
    superTitle,
    imageWrapper,
    image as imageClass,
    title as titleClass,
    lead as leadClass,
    metaWrapper,
} from './promoted-post.module.scss';
import { IPostBrief } from '../../../models/post.model';
import usePostCardData from '../../../hooks/blog/use-post-card-data';
import { useI18next } from '../../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import getTranslationKey from '../../../utils/get-translation-key';

import Date from '../../atoms/date';
import Link from '../../atoms/link';

export type PromotedPostProps = {
    post: IPostBrief;
};

export default function PromotedPost({ post }: PromotedPostProps) {
    const {
        title,
        lead,
        slug,
        publishedAt,
        mainImage: { image, alt },
    } = usePostCardData(post);
    const { t } = useI18next();
    const promotedPostTranslations = getTranslationKey('promoted-post');

    return (
        <Link to={slug} className={promotedPost}>
            <p className={superTitle}>{t(promotedPostTranslations('title'))}</p>
            <div className={imageWrapper}>
                {image && (
                    <GatsbyImage
                        alt={alt}
                        image={image}
                        className={imageClass}
                    />
                )}
            </div>
            <h4 className={titleClass}>{title}</h4>
            <p className={leadClass}>{lead}</p>
            <div className={metaWrapper}>
                <Date date={publishedAt} />
            </div>
        </Link>
    );
}
