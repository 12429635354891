import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { useI18next } from "../../../../../plugins/gatsby-plugin-ap-i18next/src/useI18next";

import { content, small, medium, avatar, info, name, titleText, bioText, signature, link, bottomParagraph } from './author-section.module.scss';
import { mainGrid } from '../../../../styles/grid.module.scss'
import { IAuthor } from "../../../../models/author.model";
import { useTranslation } from "../../../../hooks/use-translation";
import useMedia from "../../../../hooks/use-media";
import { config } from '../../../../config';

import Section from "../../../molecules/section";
import Avatar, { IAvatarProps } from "../../../atoms/avatar";
import Link from '../../../atoms/link';

const { translationKeys, relations } = config;

interface AuthorNameData {
    children: string,
    slug: string,
    className: string
};

interface IAuthorSectionProps {
    className?: string,
    author: IAuthor,
    nameTag?: React.ElementType,
    renderName?: (props: AuthorNameData) => JSX.Element,
    avatarSize?: IAvatarProps['size'],
    showSignature?: boolean,
    showLink?: boolean,
    bottomText?: React.ReactNode,
}

const AuthorSection: React.FC<IAuthorSectionProps> = ({
    className = '',
    author,
    nameTag = 'h2',
    renderName,
    avatarSize = 'large',
    showSignature = false,
    showLink = false,
    bottomText,
}) => {
    const { t } = useI18next();
    const { firstName, lastName, title, bio } = useTranslation(author, translationKeys.author);

    const [avatarImage] = useMedia({media: author.media, relation: relations.mainImage});
    const [signatureImage] = useMedia({media: author.media, relation: relations.secondaryImage});

    const NameTag = nameTag;

    return (
        <Section className={className} column="full">
            <div className={`${mainGrid} ${content} ${getSizeClass(avatarSize)}`}>
                <Avatar
                    className={avatar}
                    image={avatarImage}
                    alt={`${firstName} ${lastName}`}
                    size={avatarSize}
                />
                <div className={info}>
                    {renderName ? renderName({
                        children: `${firstName} ${lastName}`,
                        className: name,
                        slug: author.slug
                    }) : (
                        <NameTag className={name}>
                            {firstName} {lastName}
                        </NameTag>
                    )}
                    {title && (
                        <p className={titleText}>
                            {title}
                        </p>
                    )}
                    {bio && (
                        <p className={bioText}>
                            {bio}
                        </p>
                    )}
                    {signatureImage && showSignature && (
                        <GatsbyImage
                            className={signature}
                            alt={`${t('author.signature')}`}
                            image={signatureImage}
                        />
                    )}
                    {showLink && (
                        <Link
                            to={author.slug}
                            type="filled"
                            textUppercase={true}
                            className={link}
                        >
                            {t('author.link')}
                        </Link>
                    )}
                    {bottomText && (
                        <p className={bottomParagraph}>
                            {bottomText}
                        </p>
                    )}
                </div>
            </div>
        </Section>
    );
};

function getSizeClass(avatarSize: IAuthorSectionProps['avatarSize']): string {
    if (avatarSize === 'small') return small;
    if (avatarSize === 'medium') return medium;
    return '';
}

export default AuthorSection;