// extracted by mini-css-extract-plugin
export var breadcrumbs = "post-module--breadcrumbs--czolC";
export var container = "post-module--container--umlSz";
export var description = "post-module--description--Wgo6b";
export var elements = "post-module--elements--AhnJi";
export var heading = "post-module--heading---Azrv";
export var img = "post-module--img--yH2+3";
export var leftSection = "post-module--leftSection--R8BuU";
export var mainTitle = "post-module--mainTitle--6zEKS";
export var promotedPostWrapper = "post-module--promoted-post-wrapper--v-Iut";
export var rightSection = "post-module--rightSection--oTlII";
export var sectionImage = "post-module--sectionImage--QtM35";
export var singleImage = "post-module--singleImage--pn0tN";
export var socialButtons = "post-module--social-buttons--ugV4e";